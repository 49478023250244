.radio-option-label{
    padding: 0;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 0;
  } 
  .radio-option-label .radio-option{
    text-transform: capitalize;
  }  
  .radio-option-label [type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .radio-option-label [type=radio] + div {
    cursor: pointer;
  }
  .radio-option-label [type=radio] + div{
    font-weight: normal;
    background: #fff;
    padding: 12px 7rem;
    margin: 0px 5px;
    border-radius: 15px;
    font-size: 1rem;
  }
  .radio-option-label [type=radio]:checked + div {
    border: 1px solid #FD5456;
  }



@media (max-width: 768px) {
    .radio-option-label [type="radio"] + div {padding: 12px 4rem;}
}