.consultant-card{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
    cursor: pointer;
    min-height: 515px;
}
.consultant-card-title {
    font-weight: bold;
    font-size: 18px;
    color: #7b7b7b;
}
.ConsAvatar{
   height: 250px;
}
.consultant-provider{
    color: #08cad7;
    font-weight: bold;
}
.consultant-rate .rate-stars{
    margin: 0;
}
.availabilty-number{
    padding: 5px 15px;
    border: 1px solid #9bf3f8;
    font-size: 1.2rem;
    border-radius: 15px;
    display: inline-block;
    background: #9bf3f8;
    color: #07B2BD;
}
.cons-lang:nth-child(odd){
    background: #07b2bd; 
    margin: 0 5px 0 0;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
}
.cons-lang:nth-child(even){
    background: #fb5456; 
    margin: 0 5px 0 0;
    color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
}

body[lang="ar"] .availabilty-number{
    text-align: right;
    direction: rtl;
}