.HeaderTitle{
    color: #08cad7;
    font-weight: 600;
}
.HeaderPargraph{
    color: #b3b3b3;
    font-weight: 600;
}
.FormContainer{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    background: #f9f9f9;
}

.FormPadding{
    padding: 10rem;
}

.Input{
    height: 48px;
    padding: 14px 25px;
    margin: 0px 5px;
    border-radius: 15px;
    background-color: #fff;
    border: none;
    color: #848484;
}

.SignUpBackground{
    width:100%
}

.error{
    color: red;
    margin-top: 10px;
}
@media (max-width: 699px) {

    .FormPadding{
        padding: 1rem;
    }
}