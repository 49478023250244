.personalityTest{
  padding-top: 5rem;
  margin-top: 100px;
  margin-bottom: 100px;
}

.Questions [type="radio"]:checked,
.Questions [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.Questions [type="radio"]:checked + label,
.Questions [type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.Questions [type="radio"]:checked + label:before,
.Questions [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.Questions [type="radio"]:checked + label:after,
.Questions [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #fb5456;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.Questions [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.Questions [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.Question{
  background-color: #fff;
  display: flex;
  align-items: center;
  border: 2.5px solid #eee;
  padding: 1rem 2rem;
  border-radius: 15px;
  margin-bottom: 20px;
}
.QuestionFooter{
  border: solid 1px #ddd;
  background-color: #f8f8f8;
  padding: 40px;
}

.NextButton{
  text-align: center;
  width: 100%;
  background-color: #fb5456;
  outline: none;
  border: 2px solid #fb5456;
  border-radius: 15px;
  padding: 10px 35px;
  font-weight: 600;
  font-size: 1.2rem;
  
}
.NextButton:hover,
.NextButton:active{
  background-color: #fb5456 !important; 
  border: 2px solid #fb5456;
}

.NextButton:disabled{
  background-color: #919191 !important;
  border: 2px solid #919191;
  color: #fff;
  font-size: 1.2rem;
}

.PersonalityTestDesc{
  max-width: 500px;
  margin: auto;
}
.QuestionNumber{
  font-weight: bold;
}