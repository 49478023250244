.ListSearch{
    background-color: #f7f7f7;
    border: 0;
    padding: 15px 25px 20px 60px;
    border-radius: 15px;
}

.TopHead{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;
    position: relative;
}

.FilterContainer{
    position: absolute;
    right: 0;
    width: fit-content;
    top: 0;
    cursor: pointer;
}
.Filter{
    width: 50px;
    background: #e9fcf8;
    border-radius: 10px;
    padding: 10px 12px 10px 10px;
    position: relative;
    cursor: pointer;
}

@media (min-width:700px) {
    .FilterContainer{
        display: none;
    } 
}