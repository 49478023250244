.ConsultantsListSearch{
    background-color: #f7f7f7;
    border: 0;
    padding: 15px 20px 20px 50px;
    border-radius: 15px;
}

@media (max-width: 699px) {
    .ConsultantsListSearch{
        padding: 13px 20px 13px 50px;
    }
  }

