@import url('https://fonts.googleapis.com/css2?family=Stylish&family=Tajawal:wght@500&display=swap');
section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.top-p{
  font-size: 20px;
    color: #000000 !important;
    font-weight: 100;
}
.see-more{
    text-decoration: underline;
    font-weight: 500;
    font-size: 18px;
}
.text-right {
  text-align: right;
}
.main-color {
  color: #08cad7;
}
.ar-dir{
  direction: rtl;
  text-align: right;
}
.answer-label{
  margin: 0px 5px;
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    padding: 10px 2.8rem;
    cursor: pointer;
}
.answer-label-radio{
  padding: 0 2rem !important;
}
.main-icon{
    background: rgb(255, 237, 238);
    border-radius: 5px;
    padding: 10px 12px 10px 10px;
    position: relative;
    cursor: pointer;
}
.secondry-color {
  color: #fb5456;
}
.main-title {
  color: #08cad7;
  font-weight: 600;
}
.danger-title {
  color: #fb5456;
  font-weight: 600;
}
.main-black-title {
  font-weight: bold;
  color: #6e6e6e;
  margin-bottom: 15px;
}
.main-margin-top {
  margin-top: 5rem;
}
.text-font-muted {
  color: #b3b3b3;
  font-weight: 500;
}
.text-muted {
  color: #b3b3b3;
  font-weight: 500;
}
.font-weight-bold {
  font-weight: bold;
}
.main-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  margin-bottom: 25px;
  border: none;
}
.bg-Progress-Success {
  background-color: #07b2bd !important;
}
.bg-Progress-Failed {
  background-color: #fb5456 !important;
}
.bg-Progress-Improve {
  background-color: #ffb448 !important;
}
.card {
  border-radius: 15px !important;
}
.card .card-img-top {
  border-radius: 15px 15px 0 0 !important;
}

/* modal */
.modal-header {
  border-bottom: 0;
}
.modal-footer {
  border-top: 0;
}

a {
  text-decoration: none;
}

/* nav tabs */
.nav-tabs .nav-item {
  margin-bottom: 5px;
}

body > iframe {
  display: none;
}

@media (min-width: 699px) {
  .courses-list .course-image img{
    width: 180px;
    height: 160px
  }
  .modal-dialog {
    min-width: 560px;
  }
  .hideScroll {
    overflow-x: scroll;
    white-space: nowrap;
  }
}

.allSessionsContainer::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.allSessionsContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}
.allSessionsContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(left, #999, #888);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
}
/*calender style*/
.rmdp-container {
  position: relative;
}
.rmdp-input {
  border-radius: 15px;
  height: 45px;
  padding: 0 1.5rem;
  width: 125%;
  background: transparent;
  color: transparent;
  border: none;
}
.rmdp-input:focus {
  border: none;
  box-shadow: none;
}

/*drop down*/
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: white !important;
  color: #848484 !important;
  border-color: var(--bs-btn-active-border-color);
}

.dropdown-toggle::after {
  right: 2rem;
  position: absolute;
}

.dropdown-menu.show {
  width: 100%;
  border: none;
}

.dropdown .form-control:focus {
  box-shadow: none;
  border-color: #fff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fff;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
  color: #848484;
  padding: 14px 25px;
  border-radius: 10px;
  border-color: var(--maincolor);
}
.css-13cymwt-control {
  height: 48px;
  margin: 0px 5px;
  border-radius: 10px !important;
  background-color: #f5f5f5 !important;
  border: none;
  color: #848484;
  padding: 5px 5px 15px 15px;
  border-color: transparent;
  border: none !important;
}

.css-1p3m7a8-multiValue {
  background-color: #fed21a !important;
  border-radius: 5px !important;
  padding: 2px 5px !important;
  color: #000 !important;
  border-color: none !important;
}

.css-12a83d4-MultiValueRemove:hover {
  background-color: #fed21a !important;
  color: #000 !important;
}
.hideScroll {
  padding-bottom: 10px;
}
.hideScroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.hideScroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}
.hideScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fb5456;
}
.notifications-box::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.notifications-box::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}
.notifications-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #fb5456;
}

.box-border {
  padding: 1rem;
  border: 1px solid #cfcfcf;
  border-radius: 15px;
}

.courses-list{
  margin: 10px;
}
.course-rating{
  display: block !important;
}
.rate-number{
  margin: 0 !important;
}
.courses-list .course-item .course-price{
  padding: 0 !important;
  text-align: left;
}
.courses-list .course-item{
  padding: 0.5rem 1rem;
}
.courses-list .course-details{
  padding: 0 10px !important;
}
.courses-list .course-item h4{
  margin: 10px 0 20px;
  padding: 0;

}
.show-sidebar-icon{
  display: none;
}
.top-user-icons{
  display: none;
}

.top-user-icons .dropdown-menu {
  width: 300px;
  right: 0;
  left: auto;
}
.top-user-icons #dropdown-basic{
  border: none !important;
    background: #fff;
}
.top-user-icons .dropdown-toggle::after {
  border: 0;
}

@media (max-width: 699px) {
  .notifications-box{
    right: -5.5rem;
    width: 350px;
  }
  .top-web-icon{
    display: none !important;
  }
  .top-user-icons{
    display: block;
  }
  .show-sidebar-icon{
    display: block;
  }
  .courses-feedback{
    padding: 0 10px
  }
  .mobile-hidden{
    display: none;
  }
  .courses-list .course-item h4{

    font-size: 17px;
  }
  .courses-list .course-image{
    margin: 10px 10px 0px 0px
  }
  .courses-list .course-image img{
    width: 100px;
    height: 100px
  }
  .custom-tab-button{
    font-size: 15px !important;
    margin-bottom: 1rem !important;
  }
  .join-consultants{
    text-align: center;
  }
  .join-consultants button{
    margin-bottom: 2rem;
  }
  .hideScroll {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: .5rem;
  }
  .hideScroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
  }
  .hideScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparent;
  }
}
.cursor-pointer,
.rmdp-input{
  cursor: pointer;
}
.red{
  color: red;
}
.card .card-img-top {
  height: 250px;
}
.form-check{
  margin-bottom: .5rem;
}

@media (max-width:669px) {
  .top-head-sidebar {
    margin-bottom: 1rem;
  }
  .user-profile-side-bar{
      position: absolute;
      width: 70%;
      background: #fff;
      left: -1000px;
      z-index: 4;
  }
  .ranking-name{
    font-size: 15px !important;
  }
  .top-head-consultant{
    display: none;
  }
  .header{
    height: auto !important;
    padding: 20px 0;
    margin-bottom: 1rem !important;
  }
  .consultant-card{
    min-height: auto;
    margin-top: 20px;
  }
  .consultant-tabs{
    overflow: scroll;
    flex-wrap: nowrap;
    margin-bottom: 0rem !important;
  }
  .ConsultantTabsContainer .tab-content{
    padding: 1rem;
  }
  .consultant-tabs li{
    white-space: nowrap;
  }
  .consultant-tabs::-webkit-scrollbar {
    width: 0px;
  }
  .comming-soon{
    padding: 0 !important;
  }
  .comming-soon h1{
    font-size: 30px !important;
  }
  .comming-soon .main-head{
    margin-top: 10px !important;
  }
  .comming-soon .main-head p{
    font-size: 22px !important;
  }
  .comming-soon .main-head p:last-child {
    font-size: 16px !important;
}
  .store-icons{
    display: flex;
    justify-content: center;
  }
}
.social-popup iframe{
  width: 100%;
}
.my-courses-list .search-container{
  display: none;
}
.activity-icon{
  margin: 0 20px 0 0;
}
.ar-dir-container .activity-icon{
  margin: 0 0 0 20px;
} 
.Filter{
  width: 50px;
  background: #e9fcf8;
  border-radius: 10px;
  padding: 10px 12px 10px 10px;
  position: relative;
  cursor: pointer;
}
.react-tel-input > input{
    height: 48px !important;
    padding: 14px 40x !important;
    margin: 0px 5px !important;
    border-radius: 15px !important;
    background-color: #fff !important;
    border: none !important;
    color: #848484 !important;
    width: 100% !important;
}
.position-relative{
  position: relative;
}
.top-label{
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  border-radius: 0px 10px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #07b2bd;
  padding: 3px 10px;
}
/*rtl*/
body[lang="ar"]{
  direction: rtl;
}
body[lang="ar"] .activity-icon{
  margin: 0 0 0 20px;
}
/*rtl*/