.HeaderTitle{
    color: #08cad7;
    font-weight: 600;
}
.HeaderPargraph{
    color: #b3b3b3;
    font-weight: 600;
}
.FormContainer{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    background: #f9f9f9;
}

.FormPadding{
    padding: 2rem 10rem;
}

.TopImg{width: 300px;}

.FormInput{ 
    height: 48px;
    padding: 14px 25px;
    margin: 0px 5px;
    border-radius: 15px;
    background-color: #fff;
    border: none;
    color: #848484;
}

.togglePassword{
    position: absolute;
    display: inline-block;
    right: 15px;
    top: 10px;
}

.SendCode{
    color: #08cad7;
    text-decoration: underline !important;
}

/* The container */
.CheckBoxContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 17px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .CheckBoxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .Checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #fb5456;
    border-radius: 4px;
  }
  
  
  /* When the checkbox is checked, add a blue background */
  .CheckBoxContainer input:checked ~ .Checkmark {
    background-color: #fb5456;
    
  }
  
  /* Create the Checkmark/indicator (hidden when not checked) */
  .Checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the Checkmark when checked */
  .CheckBoxContainer input:checked ~ .Checkmark:after {
    display: block;
    
  }
  
  /* Style the Checkmark/indicator */
  .CheckBoxContainer .Checkmark:after {
    left: 6px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    
  }

  .TermsAndConditions{
    text-decoration: underline;
    color: #fb5456;
    font-weight: 600;
  }

  .InviteCode{
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    padding: 2rem;
  }

  .InviteCode h5{
    margin-bottom: 15px;
  }
  

  .InviteHint{
    color: #fb5456;
    opacity: .9;
    margin-top: 10px;
  }

@media (max-width: 768px) {
    .TopImg{width: 200px;}
    .FormPadding{padding: 2rem;}
}



.Drop{ 
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color:white;
  border: none;
  color: #848484;
}
.Drop:hover,
.Drop:active,
.btn:first-child:active,
.Drop.btn.show{
  background-color:white !important;
  color: #848484 !important;
}
