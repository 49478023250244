.Textarea{
    padding: 10px 20px;
    border-radius: 15px;
    border: solid 1px #efefef;
    background-color: #fafafa;
}

/*checkbox*/

.AuctionType ul {
    list-style-type: none;
  }
  
  .AuctionType li {
    display: inline-block;
  }
  
  .AuctionType input[type="radio"][id^="name"] {
    display: none;
  }
  
  .AuctionType label {
    display: block;
    position: relative;
    margin: 10px 20px;
    cursor: pointer;
    
  
  }
  
  .AuctionType label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #fb5456;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .AuctionType label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  .AuctionType :checked + label img {
    border: 2px solid #fb5456;
    padding: 3px
  }

  .AuctionType :checked + label h6 {
    color: #fb5456;
  }
  
  .AuctionType :checked + label:before {
    
  }
  
  .AuctionType :checked + label img {
    z-index: -1;
  }

  
.ProgressValue{
  position: absolute;
  top: -3.5rem;
  border-radius: 16px;
  padding: 3px 15px;
  z-index: 1;
  margin: 0;
}
.ProgressValue:after {
  
  content: '';
  content: '';
  position: absolute;
  bottom: -17px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 15px #fb5456;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}