.SurveyTest{
  padding-top: 5rem;
}

.Questions [type="radio"]:checked,
.Questions [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.Questions [type="radio"]:checked + label,
.Questions [type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.Questions [type="radio"]:checked + label:before,
.Questions [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.Questions [type="radio"]:checked + label:after,
.Questions [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #fb5456;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.Questions [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.Questions [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.Question{
  background-color: #fff;
  display: flex;
  align-items: center;
  border: 2.5px solid #eee;
  padding: 1rem 2rem;
  border-radius: 15px;
  margin-bottom: 20px;
}

.QuestionTextarea{
  padding: 10px 20px;
  border-radius: 15px;
  border: solid 1px #efefef;
  background-color: #fafafa;
}