.cancel-button{
    text-align: center;
    width: 100%;
    background-color: #fff;
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 15px 40px;
    font-weight: 600;
    color: #08cad7;
    border: 2px solid #08cad7;
    
}
.cancel-button:hover,
.cancel-button:active{
    background-color: #08cad7 !important; 
    color: #fff;
    border: 1px solid #08cad7;
}