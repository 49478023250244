.card-button{
    text-align: center;
    width: 100%;
    background-color: #fb5456;
    outline: none;
    border: 2px solid #fb5456;
    border-radius: 15px;
    padding: 15px 40px;
    font-weight: 600;
    
}
.card-button:hover,
.card-button:active{
    background-color: #fb5456 !important; 
    border: 2px solid #fb5456;
}