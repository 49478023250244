.ConsultantTabsContainer {
  width: 100%;
}
.consultant-views-container {
  position: absolute;
  right: 0;
  top: 2.5rem;
}
.ConsultantTabsContainer .tab-content {
  padding: 1rem 2rem;
}
.consultant-tabs.nav-tabs {
  border: none;
  margin-bottom: 2rem;
}
.consultant-tabs.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #fff;
}
.consultant-tabs.nav-tabs .nav-link {
  font-weight: 600;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  color: #7b7b7b;
  font-size: 1.2rem;
}
.tab-content .about-title h4 {
  font-weight: bold;
  color: #6e6e6e;
}
.consultant-tabs.nav-tabs .nav-link.active {
  color: #fb5456;
  border: none;
  border-bottom: 2px solid #fb5456;
  font-weight: 600;
}

@media (max-width: 768px) {
  .consultant-views-container {
    display: none;
  }
}

.header {
  height: 220px;
  background-color: #f8f8f8;
  margin-bottom: 3rem;
}

.breadcrumb {
  flex-direction: column;
}

.breadcrumb-header {
  color: #07b2bd;
  font-weight: 600;
}

.breadcrumb-details span {
  color: #a1a1a1;
  font-weight: 600;
}

.breadcrumb-details span.active-page {
  color: #08cad7;
}


@media only screen and (max-width: 699px) {
  .consultant-tabs.nav-tabs .nav-link {
    font-size: 16px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}