.HeaderTitle {
  margin: 2rem 0;
  text-align: center;
  border-bottom: 1px solid #e3e3e3;
  line-height: 0.1em;
}

.HeaderTitle span {
  background: #f9f9f9;
  padding: 0 10px;
}

.HeaderPargraph {
  color: #b3b3b3;
  font-weight: 600;
}
.FormContainer {
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  background: #f9f9f9;
}

.FormPadding {
  padding: 5rem 10rem;
}

.VerifyInput,
.EmailInput,
.PasswordInput {
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: #fff;
  border: none;
  color: #848484;
}

.forgetPassword {
  text-align: right;
  margin-top: 15px;
}
.CreateOne {
  text-align: center;
  width: 100%;
  background-color: #fff;
  border: 2px solid #fb5456;
  border-radius: 15px;
  padding: 10px 40px;
  font-weight: 600;
  display: inline-block;
  margin-top: 1rem;
  color: #fb5456 !important;
}
.togglePassword {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 10px;
}

.GoogleSignIn {
  padding: 14px 25px;
  border-radius: 10px;
  background-color: #fff;
  border: none;
  color: #fb5456;
  width: 100%;
  display: block;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}

.GoogleSignIn img {
  margin: 0 5px;
}

@media (max-width: 768px) {
  .FormPadding {
    padding: 2rem;
  }
}
.error {
  color: red;
  margin-top: 10px;
  margin-left: 10px;
}
