.courses-top-head{
    font-weight: bold;
    font-size: 16px;
    color: #7b7b7b;
}
.sort-container{
    margin-left: auto !important;
}

.search-container{
    position: relative;
}
.search-icon{
    position: absolute;
    left: 2%;
    top: 30%;
}
/* .pagination-container{
   position: absolute;
   bottom: 0;
   left: 40%;
} */