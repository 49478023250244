.footer{
    background-color: #07b2bd;
    color: #fff;
    padding: 2rem 2rem 0rem 2rem;
}
@media only screen and (max-width: 699px) {
    .footer{
        padding: 4rem 2rem;
    }
}
.FooterLogo{
    text-align: center;
}
.FooterLogo img{
    filter: brightness(0) invert(1);
    width: 50px;
    margin-bottom: 2rem;
}

.FooterTitle{
    font-weight: bold;
    font-size: 1.2rem;
}
.Sitemap > p{
    margin-bottom: 3px;
}
.SiteLink a{
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

.ContactImg{
    width: 35px;
    height: auto;
    margin-right: 10px;
    border-radius: 50%;
}
.CopyRight{
    background: #02a9b4;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.SocialIcons{
    font-size: 50px;
}
.SocialIcon {
    background: #34bdc7;
    padding: 5px;
    border-radius: 50%;
    margin-left: 10px;
}
.aboutNurabi{
    font-size: 16px
}