.RadioOption {
  padding: 0.6rem 1rem;
  border-radius: 15px;
  border: 1px solid #fb5456;
}
.RadioOption [type='radio']:checked,
.RadioOption [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.RadioOption [type='radio']:checked + label,
.RadioOption [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 10px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  width: 100%;
}
.RadioOption [type='radio']:checked + label:before,
.RadioOption [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.RadioOption [type='radio']:checked + label:after,
.RadioOption [type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #fb5456;
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.RadioOption [type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.RadioOption [type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.RadioOption [type='radio']:checked + label:before {
  border: 1px solid #fb5456;
}

.DisabledInput {
  background: #e7e7e7;
  border: none !important;
}

.DisabledInput [type='radio']:checked + label:after,
.DisabledInput [type='radio']:not(:checked) + label:after,
.DisabledInput [type='radio']:checked + label:before,
.DisabledInput [type='radio']:not(:checked) + label:before {
  content: unset;
}
