.Image{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 8px 24px 0 rgba(183, 183, 183, 0.17);
    background-color: #fff;
    margin: 2rem auto 0rem;
}


.Card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
  }
  
  .Card .Percent {
    position: relative;
  }
  
  .Card svg {
    position: relative;
    width: 70px;
    height: 70px;
    transform: rotate(-90deg);
  }
  
  .Card svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #f0f0f0;
    stroke-width: 5;
    stroke-linecap: round;
  }
  
  .Card svg circle:last-of-type {
    stroke-dasharray: 208px;
    stroke-dashoffset: calc(208px - (208px * var(--percent)) / 100);
    stroke: #fb5456; 
  }
  
  .Card .Number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .Card .Number h3 {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
  }
  
  .Card .Number h3 span {
    font-size: 1rem;
  }
  
  .Card .title h2 {
    margin: 25px 0 0;
  }
  
  .Card:nth-child(2) svg circle:last-of-type {
    stroke: #2ecc71;
  }

  .Type{
    margin-left: 10px;
    color: #fed21a;
    font-size: 1.3rem;
    font-weight: 600;
  }